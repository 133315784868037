/* eslint-disable no-undef */
/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { language } from '../shared/constants';
import { alertOffline } from '../shared/notification';

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_HOTEL,
  timeout: 60000,
});

Api.interceptors.request.use(async (config) => {
  if (process.env.REACT_APP_SPAG_KEY) {
    config.headers.common['Sbkey'] = process.env.REACT_APP_SPAG_KEY;
  }
  config.headers.common['Content-Language'] = language;

  return config;
}, async (error) => {
  console.log('error interceptors', error);
});

export const verifyConnection = async () => {
  try {
    const res = await Api.get('/srap/verify-connection');

    if (res && res.data && res.data.connected) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }

  await alertOffline();

  return false;
};

export const verifyOffline = async () => {
  try {
    if ('onLine' in navigator) {
      if (navigator.onLine === true) {
        return true;
      }
    } else {
      return true;
    }
  } catch (error) {
    console.error(error);
  }

  await alertOffline();

  return false;
};
