/* eslint-disable no-undef */
export function toogleSkeleton(hash) {
  if (hash !== undefined) {
    document.getElementById('wait-skeleton').classList.toggle('d-none');
    document.getElementById('root').classList.toggle('d-block');
  }
}

export function toogleSkeletonNotFound() {
  if (document.getElementById('not-found')) {
    document.getElementById('wait-skeleton').classList.toggle('d-none');
    document.getElementById('root').classList.toggle('d-block');
  }
}
