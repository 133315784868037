import React, { createContext, useContext, useState } from 'react';

const PersonalDataContext = createContext();

export default function PersonalDataProvider({ children }) {
  const [personalData, setPersonalData] = useState();

  return (
    <PersonalDataContext.Provider
      value={{
        personalData,
        setPersonalData,
      }}
    >
      {children}
    </PersonalDataContext.Provider>
  );
}

export function usePersonalData() {
  return useContext(PersonalDataContext);
}
