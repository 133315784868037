/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from 'react-router-dom';

import { IntlProvider, FormattedMessage } from 'react-intl';
import SaleProvider from './context/PopulateDataAplication';
import Storage from './services/storage';
import { useIdioma } from './context/language';
import { translate } from './shared/translate';
import Spinner from './components/Spinner';
import Spag from './pages/Spag';
import { useAuth } from './context/AuthContext';
import ButtonTryAgain from './components/ButtonTryAgain';

const Erro404 = React.lazy(() => import('./pages/Erro404'));
const Footer = React.lazy(() => import('./components/Footer'));
const Register = React.lazy(() => import('./pages/Register'));
const Pay = React.lazy(() => import('./components/DataPayments'));
const Finalize = React.lazy(() => import('./pages/Finalize'));

function Routes() {
  const { idioma } = useIdioma();
  const { auth } = useAuth();
  const id = window.location.pathname.replace('/', '').split('/');

  const language = Storage.getCookie('Spag-language') || idioma;
  const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route
      {...rest}
      render={(props) => (auth ? (
        <Component {...props} />

      ) : (
        <ButtonTryAgain />
      ))}
    />
  );

  const DefaultContainer = () => (
    <Spag>
      <Switch>
        <PrivateRoute exact path="/:id" component={Register} />
        <PrivateRoute exact path="/:id/pagamento" component={Pay} />
        <PrivateRoute exact path="/:id/finalizar" component={Finalize} />
        <PrivateRoute path="*" component={Erro404} />
      </Switch>
    </Spag>
  );

  return (
    <IntlProvider locale="pt" messages={translate[language]}>
      <Router>
        <React.Suspense fallback={<Spinner title={<FormattedMessage id="loading" defaultMessage="Carregando..." />} />}>
          <SaleProvider route={id[0]}>
            <Switch>
              <Route exact path="/nao-encontrada" component={Erro404} />
              <PrivateRoute component={DefaultContainer} />
            </Switch>
            <Footer />
          </SaleProvider>
        </React.Suspense>
      </Router>
    </IntlProvider>
  );
}

export default Routes;
