import React from 'react';
import { useSale } from '../context/PopulateDataAplication';
import divisionFormatBRL from '../shared/divisionFormatBRL';

export default function Total() {
  const { spag } = useSale();

  return (
    <div className="total mt-3">
      <div className="col-12 d-flex justify-content-around">
        <h2 className="data-sale">Total</h2>
        <h2 className="price-sale">{divisionFormatBRL(spag.sale.amount)}</h2>
      </div>
    </div>
  );
}
