/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export function initSentry() {
  Sentry.init({
    dsn: 'https://926f1c3d33294aa1b9299684572d9abd@o402287.ingest.sentry.io/5702285',
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [
      'Request failed with status code 403',
      'Request failed with status code 404',
    ],
    whitelistUrls: [
      'pay.silbeck.com',
      'pay.teste.silbeck.com.br',
    ],
    release: `spagfront@${process.env.REACT_APP_VERSION}`,
  });
}

export function logError(error) {
  Sentry.captureException(error);
}

export function onError(error) {
  let errorInfo = {};
  let message = error.toString();

  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error);
}
