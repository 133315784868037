import React, { createContext, useState, useContext } from 'react';
import { language } from '../shared/constants';

const LanguageContext = createContext();

export default function LanguageProvider({ children }) {
  const [idioma, setIdioma] = useState(language);
  return (
    <LanguageContext.Provider
      value={{
        idioma,
        setIdioma,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export function useIdioma() {
  return useContext(LanguageContext);
}
