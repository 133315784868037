/* eslint-disable no-use-before-define */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-object-spread */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import checkApi from '../services/checkApi';
import { onError } from '../services/errorSentry';
import Storage from '../services/storage';
import { SALE_STATUS } from './constants';
import { isMobile } from './layout';
import { translate } from './translate';

export const MySwal = withReactContent(Swal);

export const alertCookies = function (options, visible) {
  if (visible) {
    MySwal.fire(
      Object.assign({
        width: isMobile() ? 'auto' : '32rem',
        position: 'bottom-left',
        backdrop: false,
        allowOutsideClick: false,
        text: options.alertCookies,
        customClass: {
          confirmButton: 'button-cookie',
          cancelButton: 'button-cookie',
        },
        confirmButtonColor: '#71DC56',
        confirmButtonText: options.btnAceppt,
        cancelButtonText: options.btnRefuse,
        showCancelButton: true,
      }),
    ).then((result) => {
      if (result.isConfirmed) {
        Storage.setCookie('_acept', true, '', 7);
      } else {
        Storage.setCookie('_acept', false, '', 7);
      }
    });
  }
};

export const alertSuccess = function (options, btn, timer) {
  MySwal.fire(
    Object.assign({
      position: 'center',
      icon: 'success',
      title: options.alertSucessTitle,
      text: options.alertSucessText,
      showConfirmButton: typeof btn !== 'undefined' ? btn : false,
      timer: typeof timer !== 'undefined' ? timer : 2000,
    }),
  );
};

export const toastSuccess = function (text, options) {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: text ? false : true,
  });

  Toast.fire(
    Object.assign(
      {
        icon: 'success',
        title: text || 'As informações foram salvas!',
      },
      options,
    ),
  );
};

export const alertError = function (options, errorMessage, statusError) {
  const language = Storage.getCookie('Spag-language') || 'pt-br';
  return MySwal.fire(
    Object.assign(
      {
        position: 'center',
        icon: 'warning',
        titleText: 'Oops..',
        html: `<p data-testid='error-sale'>${errorMessage || 'Ocorreu um erro interno na aplicação.'
        }</p>`,
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: `${translate[language].tryAgainPay}`,
        cancelButtonText: `${translate[language].cancelPay}`,
        confirmButtonColor: '#71DC56',
      },
      options,
    ),
  )
    .then((result) => {
      if (result.isDismissed || (result.isConfirmed && statusError)) {
        window.location.reload();
      }
    })
    .catch((error) => console.error(error));
};

export const errorPix = function (callbackRedirect, spag, callbackState) {
  const language = Storage.getCookie('Spag-language') || 'pt-br';
  return MySwal.fire(
    Object.assign({
      position: 'center',
      icon: 'error',
      title: `${translate[language].timeoutPix}`,
      html: `<div class="container">
              <div class="row">
                <div class="col-12">
                  <p>${translate[language].msgErrorPix}</p>
                </div>
              </div>
            </div>`,
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText:
        translate[language].confirmButtonExpired || 'Gerar novo código',
      cancelButtonText: translate[language].cancelButtonExpired || 'Atualizar',
      confirmButtonColor: '#71DC56',
    }),
  ).then((result) => {
    if (result.isConfirmed && callbackRedirect) {
      callbackRedirect();
    } else if (result.isDismissed) {
      checkApi(spag)
        .then((res) => {
          if (res.status === SALE_STATUS.wait) {
            callbackState({ ...res, type: 'pix' });
          } else if (res.status === SALE_STATUS.paid) {
            callbackState(res);
          } else if (res.status === SALE_STATUS.expired) {
            alertWarning(
              translate[language].pixExpired
              || 'O link expirou. Repita a operação!',
            );
            callbackRedirect();
          } else {
            alertError({ icon: 'error' }, translate[language].errorServer);
          }
        })
        .catch((error) => {
          onError(error);
          alertError({ icon: 'error' }, translate[language].errorTransaction);
        });
    }
  });
};

export const showModal = function (options, name, terms, callback, callbackSubmit) {
  const language = Storage.getCookie('Spag-language') || 'pt-br';
  return MySwal.fire(
    Object.assign({
      heightAuto: false,
      position: 'center',
      width: 1200,
      scrollbarPadding: true,
      customClass: {
        htmlContainer: 'content-alert',
        closeButton: 'close-button',
        popup: 'modal',
      },
      title: `<div class="mt-4">${name.name}</div>`,
      showCloseButton: true,
      confirmButtonColor: '#71DC56',
      cancelButtonColor: '#AAA',
      confirmButtonText: terms !== undefined ? translate[language].accept : 'Ok',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: translate[language].notAccept,
      html:
        terms === undefined
          ? `<div class="container mt-5 mb-3" data-testid="modal">
            <div class="row">
              <div class="col-lg col-md-8 my-2 px-0" style=${name.email === undefined || name.email === '' ? 'display:none' : 'display:block'}>
                <span class="material-icons mail">mail</span>E-mail: <a class="link-support" href="mailto:${name.email}">${name.email}</a>
              </div>
              <div class="col-lg col-md-8 my-2 px-0" style=${name.phoneNumber === undefined || name.phoneNumber === '' ? 'display:none' : 'display:block'}>
                <span class="material-icons mail">call</span>Telefone: <a class="link-support" href="tel:${name.phoneNumber}">+55 ${name.phoneNumber}</a>
              </div>
              <div class="col-lg col-md-8 my-2 px-0" style=${name.whatsappNumber === undefined || name.whatsappNumber === '' ? 'display:none' : 'display:block'}>
                <span class="material-icons mail">
                  smartphone
                </span>Whatsapp: 
              <a class="link-support" href="${name.link}" target="_blank" rel="noopener noreferrer">+55 ${name.whatsappNumber}</a></div>
             </div>
          </div> `
          : `<br><div class="policy-text" data-testid="terms">${terms}</div>`,
      allowEscapeKey: true,
    }, options),
  ).then((result) => {
    if (result.isConfirmed && callback) {
      callback('accepted');
      if (callbackSubmit) {
        callbackSubmit();
      }
    } else if (result.isDismissed && callback) {
      callback('denied');
    }
  });
};

export const showLoading = function (translateLoad, callback, obj) {
  return MySwal.fire(
    Object.assign({
      title: translateLoad ? translateLoad.alertLoadTitle : 'Carregando...',
      text: translateLoad ? translateLoad.alertLoadText : 'aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      timerProgressBar: true,
      didOpen: async () => {
        MySwal.showLoading();
        try {
          await callback;
        } catch (error) {
          console.error(error);
        }
      },
    }),
    obj,
  );
};

export function confirmDelete(callback) {
  MySwal.fire({
    position: 'top',
    title: 'Você tem certeza?',
    text: 'Deseja realmente excluir esse item?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#b3b3b3',
    confirmButtonText: 'Sim, excluir!',
    cancelButtonText: 'Cancelar',
  }).then(async (result) => {
    if (result.value) {
      if (await callback) {
        alertSuccess({
          title: 'Excluído!',
          text: 'O item foi deletado.',
        });
      }
    }
  });
}

export const alertWarning = function (textWarning, btn, timer) {
  MySwal.fire(
    Object.assign({
      position: 'center',
      icon: 'warning',
      title: 'Oops',
      text: textWarning,
      showConfirmButton: typeof btn !== 'undefined' ? btn : false,
      timer: typeof timer !== 'undefined' ? timer : 2000,
    }),
  );
};
