/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { alertError } from '../shared/alert-handler';
import { language } from '../shared/constants';
import { translate } from '../shared/translate';

export function validateStatusCodeApi(status, callback) {
  if ((status.response === undefined || status.response.status === 404) && callback) {
    alertError({ showConfirmButton: false, titleText: translate[language].ops }, translate[language].notExist);
  } else {
    alertError({ titleText: translate[language].ops }, translate[language].notExist, status.response.status);
  }
}
