import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function CookiesDisabled() {
  return (
    <div className="show-alert mb-1">
      <p data-testid="cookies">
        <FormattedMessage
          id="msgCookies"
          defaultMessage="Recomendamos que ative os COOKIES do navegador, para melhor uso do sistema!"
        />
      </p>
    </div>
  );
}
