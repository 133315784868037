/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import Cookies from 'js-cookie';

export default class Storage {
  static TOKEN_KEY = 'sptk';

  static USER_KEY = 'spu';

  static HOTEL_KEY = 'sph';

  static COOKIES_ENABLED = navigator.cookieEnabled

  // Local storage
  static setItem = async (key, data, isStr) => {
    if (!isStr) {
      data = JSON.stringify(data);
    }
    try {
      localStorage.setItem(key, data);
    } catch (error) {
      console.error(error);
    }
  }

  static getItem = (key, isStr) => {
    let data = this.COOKIES_ENABLED ? localStorage.getItem(key) : false;

    if (!isStr) {
      data = JSON.parse(data);
    }

    return data || false;
  }

  static getItemAsync = async (key, isStr) => this.getItem(key, isStr)

  static removeItem = async (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  }

  // Local Cookies
  static setCookie = async (key, value, path, expire) => {
    const options = { secure: process.env.REACT_APP_MODE_DEVELOP === 'true' ? false : true, sameSite: 'Strict' };

    if (path) {
      options.path = path;
    }

    if (expire > 0) {
      options.expires = expire;
    }

    Cookies.set(key, value, options);
  }

  static getCookie = (key) => Cookies.get(key) || false

  static removeCookie = async (key, path) => {
    if (path) {
      Cookies.remove(key, { path: `/${path}` });
    } else {
      Cookies.remove(key);
    }
  }
}
