/* eslint-disable react/no-array-index-key */
import React from 'react';
import divisionFormatBRL from '../shared/divisionFormatBRL';
import Spinner from './Spinner';

export function calculatePrice(price, quantity) {
  return (price * quantity);
}

export default function ItemBooking({ visible, sale }) {
  return (
    <>
      {visible
        && (
        <div className="items-sale d-lg-block" data-testid="list-itens">
            {(sale.itens === undefined) ? <Spinner /> : sale.itens.map((item, indice) => (
              <div key={indice} className="col-12 d-flex justify-content-around mt-3 ">
                <div className="data-sale">
                  {item.title}
                </div>
                <div className="price-sale">{divisionFormatBRL(calculatePrice(item.unitPrice, item.quantity))}</div>
              </div>
            ))}
        </div>
        )}
    </>
  );
}
