import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSale } from '../context/PopulateDataAplication';
import { isMobile } from '../shared/layout';
import ItemBooking from './ItemBooking';

export default function Booking({ itensSale }) {
  const { spag } = useSale();
  const [visible, setVisible] = useState(true);
  const { sale } = spag;

  useEffect(() => {
    if (isMobile()) {
      setVisible(false);
    }
  }, []);

  return (
    <>
      <div className={itensSale.length > 0 ? 'col-12 d-flex justify-content-around' : 'col-12 d-flex justify-content-center'}>
        <div className="px-0 my-4">
          {sale.description}
        </div>
        <div className="px-0">
          {itensSale.length > 0
           && (
           <button
             type="button"
             className="btn-sale my-4 ml-3"
             onClick={() => (visible ? setVisible(false) : setVisible(true))}
           >
             <FormattedMessage
               id={visible ? 'btn-less' : 'btn-more'}
               defaultMessage="Ocultar detalhes"
             />
           </button>
           )}
        </div>
      </div>
      <ItemBooking visible={visible} sale={sale} />
    </>
  );
}
