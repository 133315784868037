/* eslint-disable max-len */
/* eslint-disable no-console */
import React, {
  createContext, useState, useContext, useEffect,
} from 'react';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { typePayments } from '../shared/constants';
import { Api } from '../services/api';
import { useIdioma } from './language';
import Storage from '../services/storage';
import { redirectRoute } from '../services/redirect';
import { populatePaymentType } from '../services/populatePaymentType';
import { validateDateLocalStorage } from '../services/clearLocalStorage';
import { useAuth } from './AuthContext';
import { toogleSkeleton } from '../shared/skeleton';
import { validateStatusCodeApi } from '../services/validateStatusCode';
import { alertError } from '../shared/alert-handler';
import { translate } from '../shared/translate';
import { onError } from '../services/errorSentry';

const SaleContext = createContext();

export default function SaleProvider({ children, route }) {
  const [spag, setSpag] = useState();
  const [processingPayment, setProcessingPayment] = useState(false);
  const [paid, setPaid] = useState(false);
  const [terms, setTerms] = useState();

  const { idioma } = useIdioma();
  const { setAuth } = useAuth();
  const language = Cookies.get('Spag-language') || idioma;
  const history = useHistory();
  const { search } = useLocation();
  const { email, name } = queryString.parse(search);

  useEffect(() => {
    if (email !== undefined && name !== undefined) {
      Storage.setItem('params', { name, email });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    validateDateLocalStorage(Storage.getItem(route).day, route);
    const searchApi = async () => {
      try {
        const response = await Api.get(`/pay-link/init/${route}`);
        Storage.setCookie('Token-pgto', response.data.sale.hash);
        if (response.data.sale.hash === route) {
          populatePaymentType(response.data.payments, typePayments);
          setSpag(response.data);
          setAuth(response.data.settings.token);
          redirectRoute(response.data.sale.status, route, history.push);
          toogleSkeleton(response.data.sale.hash);
        } else {
          alertError({ showConfirmButton: false, titleText: translate[language].ops }, translate[language].notExist);
        }
      } catch (error) {
        onError(error);
        validateStatusCodeApi(error, history.push);
        console.error(error);
      }
    };
    if (route.length === 10) {
      searchApi();
    } else {
      alertError({ showConfirmButton: false, titleText: translate[language].ops }, translate[language].notExist);
    }
    return () => searchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, setAuth, route]);

  useEffect(() => {
    if (spag !== undefined && spag.settings.showPaymentTerms === true) {
      try {
        Api.get(`/pay-link/texts/payment-policy/${spag.tenant.id}`, {
          headers: {
            Authorization: `Bearer ${spag.settings.token}`,
            'Content-Language': language,
          },
        })
          .then((res) => {
            setTerms(res.data.text);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        onError(error);
        console.error(error);
      }
    }
  }, [spag, language]);

  return (
    <SaleContext.Provider
      value={{
        language,
        spag,
        terms,
        processingPayment,
        setProcessingPayment,
        paid,
        setPaid,
      }}
    >
      {children}
    </SaleContext.Provider>
  );
}

export function useSale() {
  return useContext(SaleContext);
}
