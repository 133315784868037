import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function ButtonTryAgain() {
  return (
    <div className="row justify-content-center mt-auto mb-5">
      <div className="col-12">
        <button
          data-testid="button-try-again"
          type="button"
          className="btn try-again"
          onClick={() => window.location.reload()}
        >
          <FormattedMessage
            id="btnWait"
            defaultMessage="Buscar novamente"
          />
        </button>
      </div>
    </div>
  );
}
