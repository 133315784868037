import { Api } from './api';
import { onError } from './errorSentry';
import { validateStatusCodeApi } from './validateStatusCode';

export default function checkApi(spag) {
  const send = Api.get(`/pay-link/check/${spag.sale.hash}`, {
    headers: {
      Authorization: `Bearer ${spag.settings.token}`,
    },
  })
    .then((res) => res.data)
    .catch((error) => {
      onError(error);
      validateStatusCodeApi(error, window.location.reload());
      console.error(error);
    });
  return send;
}
