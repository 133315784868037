/* eslint-disable import/prefer-default-export */
import { SALE_STATUS } from '../shared/constants';
import Storage from './storage';

export function redirectRoute(status, id, history) {
  switch (status) {
    case SALE_STATUS.open:
      history(`/${id}`);
      break;
    case SALE_STATUS.paid:
      Storage.setCookie('status', status);
      Storage.removeCookie('form', id);
      history(`/${id}/finalizar`);
      break;
    case SALE_STATUS.wait:
      Storage.setCookie('Token-pgto', 'true');
      Storage.setCookie('status', status);
      history(`/${id}/finalizar`);
      break;
    default:
      break;
  }
}
