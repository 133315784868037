/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function Spinner(props) {
  const [time, setTime] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (time === true) {
      window.location.reload();
    }
  }, []);

  return (
    <>
      {time === false
        && (
          <div className="container">
            <div className="waiting">
              <div className="row justify-content-center">
                <div className="col-6 waiting-container">
                  <h2>{props.title}</h2>
                </div>
              </div>
              <div className="row align-items-center mt-4">
                <div className="col-12">
                  {props.children}
                  <div className="load-line">
                    <div className="line" />
                    <div className="line" />
                    <div className="line" />
                    <div className="line" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
}
