/* eslint-disable no-plusplus */
/* eslint-disable keyword-spacing */
/* eslint-disable prefer-const */
/* eslint-disable space-before-blocks */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
import { format, parseISO } from 'date-fns';

export const formatBRL = (value, cifrao) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  if (cifrao) {
    return formatter.format(value).replace('R$', cifrao);
  }

  return formatter.format(value);
};

export const formatPercent = (value, round) => {
  if (value > 0 || value < 0) {
    if (round) {
      value = Math.round(parseFloat(value));
    } else {
      value = parseFloat(value).toFixed(1).replace('.', ',');
    }
  } else {
    value = 0;
  }

  return `${value}%`;
};

export const searchString = (str, search) =>
  String(str).toLowerCase().includes(search.toLowerCase());

export const dateFormatBr = (date) => {
  if (date) {
    if (typeof date === 'object') {
      return format(date, 'dd/MM/yyyy');
    } if (typeof date === 'string') {
      return dateFormatBr(parseISO(date));
    }
  }

  return '';
};

export function yearCard(){
  let date = new Date().getFullYear();
  let arrayYear = [date];
  for(let i = 0; i < 15; i++){
    date += 1;
    arrayYear.push(date);
  }
  return arrayYear;
}

export function monthCard(mes, ano) {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;

  if((mes >= month && ano >= year) || (mes <= month && ano > year)){
    return true;
  }
  return false;
}
