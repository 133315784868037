/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CookiesDisabled from '../components/CookiesDisabled';
import Menu from '../components/Menu';
import Sidebar from '../components/Sidebar';
import Spinner from '../components/Spinner';
import PersonalDataProvider from '../context/PersonalDataContext';
import { useSale } from '../context/PopulateDataAplication';
import Storage from '../services/storage';
import { alertCookies } from '../shared/alert-handler';
import { translate } from '../shared/translate';

export default function Spag({ children }) {
  const { language, spag } = useSale();
  const [cookiesDisabled, setCookiesDisabled] = useState(false);

  const bottomRef = useRef();

  const scrollToBottom = () => {
    if (bottomRef.current !== undefined) {
      bottomRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (!Storage.getCookie('_acept') && spag !== undefined) {
      !navigator.cookieEnabled
        ? setCookiesDisabled(true) : alertCookies((translate[language]), spag.tenant);
    }

    scrollToBottom();
  }, [language, spag]);

  return (
    <>
      {spag !== undefined
        ? (
          <div className={cookiesDisabled ? 'container mt-1' : 'container mt-3'} id="api">
            <div className="data-form">
              <div className="row">
                <div className="col-lg-8 mt-0 order-lg-1 order-2 pr-lg-1" ref={bottomRef}>
                  <Menu sale={spag.sale} />
                  {cookiesDisabled === true
                    && <CookiesDisabled />}
                  <PersonalDataProvider>
                    {children}
                  </PersonalDataProvider>
                </div>
                <div className="col-lg-4 mt-0 px-0 order-lg-2 order-1 pl-lg-4">
                  <Sidebar
                    tenant={spag.tenant}
                    language={language}
                    hash={spag.sale.hash}
                    itens={spag.sale.itens}
                    settings={spag.settings}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : <Spinner title={<FormattedMessage id="loading" defaultMessage="Carregando..." />} />}
    </>
  );
}
