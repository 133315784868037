/* eslint-disable no-nested-ternary */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';
import { useIdioma } from '../context/language';
import Total from './Total';
import brazil from '../assets/brands/Brazil.svg';
import spain from '../assets/brands/Spain.svg';
import usa from '../assets/brands/USA.svg';
import escudo from '../assets/escudo/escudoMobile.svg';
import escutodesktop from '../assets/escudo/escudo.svg';
import { showModal } from '../shared/alert-handler';
import Booking from './Booking';

export default function Sidebar({
  tenant, language, hash, itens, settings,
}) {
  const { setIdioma } = useIdioma();

  const handleChangeLanguage = (event) => {
    Cookies.set('Spag-language', event.target.value);

    if (Cookies.get('Spag-language')) {
      setIdioma(Cookies.get('Spag-language'));
    } else {
      setIdioma(event.target.value);
    }
  };
  const link = tenant.whatsappNumber && { link: `https://api.whatsapp.com/send?phone=55${tenant.whatsappNumber.replace(/\D/g, '')}&text=Contato%20link%20de%20pagamento%20${hash}` };
  const dataModal = Object.assign(tenant, link);

  return (
    <>
      <div className="payment-identifier">
        <div className="hotel-identifier d-flex px-2 py-2">
          <div className="col-4">
            <div className="img-hotel" style={tenant.imgColor && { backgroundColor: tenant.imgColor }}>
              {tenant.img && <img alt="logo-hotel" className="picture" data-testid="img-sidebar" src={tenant.img} />}
            </div>
          </div>
          <div className="col-8 mt-4 ml-2">
            <div className="hotel-name">
              {tenant.name}
            </div>
            <div className="central">
              <span className="material-icons">
                support_agent
              </span>
              {' '}
              <span
                aria-live="polite"
                tabIndex="-1"
                role="button"
                className="link-central link"
                onClick={() => showModal({
                  showCancelButton: false,
                  showConfirmButton: false,
                }, dataModal)}
              >
                <FormattedMessage
                  id="help-desk"
                  defaultMessage="Central de atendimento"
                />
                {' '}
              </span>
            </div>
          </div>
        </div>
        <div className="box-payment">
          <div className="col-12 d-flex justify-content-around">
            <div className="col-6 px-0">
              <div className="box-pay">
                <img src={escudo} alt="shield" className="img shield" />
                <div className="fundo">
                  <img src={escutodesktop} alt="" className="img-shield d-none d-lg-inline-block" />
                  <span className="texto-seguro">
                    <FormattedMessage
                      id="secure-payment"
                      defaultMessage="PAGAMENTO SEGURO"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 px-0">
              <div className="select-language">
                {(language === 'pt-br') && <img src={brazil} alt="img-brand-brazil" className="img brand" />}
                {(language === 'en-us') && <img src={usa} alt="img-brand-usa" className="img brand" />}
                {(language === 'es-es') && <img src={spain} alt="img-brand-spain" className="img brand" />}
                {settings.languages !== undefined
                  && (
                  <select
                    value={language}
                    name="Language"
                    className="language"
                    onChange={handleChangeLanguage}
                  >
                    {settings.languages.map((languages) => (
                      <option key={languages} value={languages}>
                        {languages === 'pt-br'
                          ? 'Português'
                          : languages === 'en-us'
                            ? 'English'
                            : 'Español'}
                      </option>
                    ))}
                  </select>
                  )}
              </div>
            </div>
          </div>
        </div>
        <Booking itensSale={itens} />
        {/* <Subtotal /> */}
        <Total />
      </div>
    </>
  );
}
