import Storage from './storage';

export default function clearLocalStorage(id) {
  Storage.removeItem(id);
  Storage.removeItem('params');
  Storage.removeCookie('formPagto');
  Storage.removeCookie('cep', id);
  Storage.removeCookie('form', id);
  Storage.removeCookie('Token-pgto');
  Storage.removeCookie('filter');
  Storage.removeCookie('Pais');
  Storage.removeCookie('status');
  Storage.removeCookie('Country');
  Storage.removeCookie('name');
  Storage.removeCookie('email');
}

export const dateLocalStorage = () => {
  const data = new Date();
  const validateDate = {
    day: data.getDate(),
    month: data.getMonth(),
  };
  return validateDate;
};

export function validateDateLocalStorage(day, id) {
  const date = new Date();
  const currentDate = date.getDate();

  if (day !== currentDate) {
    clearLocalStorage(id);
  }
}
