/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AuthProvider from './context/AuthContext';
import LanguageProvider from './context/language';
import { initSentry } from './services/errorSentry';

import './sass/app.scss';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_MODE_DEVELOP === 'false') {
  initSentry();
}

const rootNode = document.getElementById('root');
ReactDOM.render(
  <LanguageProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </LanguageProvider>, rootNode,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
