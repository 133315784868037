/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { colors } from '../shared/constants';
import Storage from '../services/storage';

export default function Menu({ sale }) {
  const location = useLocation();
  const history = useHistory();

  const { filled = colors.filled } = colors;
  const { progress = colors.progress } = colors;

  const register = `/${sale.hash}`;
  const payment = `/${sale.hash}/pagamento`;
  const confirmed = `/${sale.hash}/finalizar`;

  return (
    <div className="row justify-content-center">
      <div className="item-menu" data-testid="menu">
        <ul className="prime" data-testid="footer">
          <li
            aria-live="polite"
            tabIndex="-1"
            role="button"
            className="menu-listing"
            onClick={() => history.push(register)}
            id="space-id"
            style={location.pathname === confirmed ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
          >
            <span
              className="order"
              style={location.pathname === register ? {} : filled}
            >
              1
            </span>
            <div className="menu-title">
              <FormattedMessage
                id="menu-identification"
                defaultMessage="Identificação"
              />
            </div>
          </li>
          <li
            aria-live="polite"
            tabIndex="-1"
            role="button"
            className="menu-listing"
            onClick={() => history.push(payment)}
            style={Storage.getCookie('form') ? { pointerEvents: 'auto' } : { pointerEvents: 'none' }}
            id="space-item"
          >
            <span
              className="order"
              id="final"
              style={location.pathname === payment
                ? progress
                : location.pathname === confirmed ? filled : {}}
            >
              2
            </span>
            <div className="menu-title">
              <FormattedMessage
                id="menu-payment"
                defaultMessage="Pagamento"
              />
            </div>
          </li>
          <li className="menu-listing" style={{ pointerEvents: 'none' }}>
            <span
              className="order"
              id="final"
              style={location.pathname === confirmed ? progress : {}}
            >
              3
            </span>
            <div className="menu-title">
              <FormattedMessage
                id="menu-confirmation"
                defaultMessage="Confirmação"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
