/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import locale_en from '../translations/en.json';
import locale_pt from '../translations/pt.json';
import locale_es from '../translations/es.json';

export const translate = {
  'pt-br': locale_pt,
  'en-us': locale_en,
  'es-es': locale_es,
};
