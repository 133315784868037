/* eslint-disable no-console */
/* eslint-disable no-undef */
import history from './history';
import { alertCookies } from './alert-handler';
import { Api } from '../services/api';

const clearNotify = () => delete window.sbnotify;

export const showNotification = async (id) => {
  try {
    const { data } = await Api.get(`/srap/notificacoes/${id}`);

    if (data && data.id) {
      if (data.acao === 9) {
        window.location.reload(true);
        clearNotify();
      } else {
        alertCookies({
          position: 'top',
          icon: data.tipo === 1 ? 'success' : 'info',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          title: data.titulo,
          text: data.descricao,
          onClose: () => {
            if (data.acao === 1) {
              window.location.reload(true);
            } else if (data.acao === 2) {
              history.push('/sair');
            }

            clearNotify();

            return false;
          },
        });
      }
    }
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const alertOffline = async () => {
  alertCookies({
    position: 'top',
    icon: 'info',
    showConfirmButton: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
    title: 'Falha na conexão',
    text: 'Não foi possível conectar ao servidor, tente novamente em alguns instantes!',
  });

  return false;
};
