/* eslint-disable quotes */
/* eslint-disable quote-props */
/* eslint-disable comma-dangle */
/* eslint-disable object-shorthand */
import visa from '../assets/cartao/visa.svg';
import mastercard from '../assets/cartao/mastercard.svg';
import alipay from '../assets/cartao/alipay.svg';
import elo from '../assets/cartao/elo.svg';
import diners from '../assets/cartao/diners.svg';
import hipercard from '../assets/cartao/hipercard.svg';
import generic from '../assets/cartao/generic.svg';
import amex from '../assets/cartao/amex.svg';
import discover from '../assets/cartao/discover.svg';
import maestro from '../assets/cartao/maestro.svg';
import jcb from '../assets/cartao/jcb.svg';
import Storage from '../services/storage';
import credit from '../assets/payment/card.svg';
import pixMethod from '../assets/pix/pixnew.svg';

export const iconBrands = {
  visa: visa,
  mastercard: mastercard,
  amex: amex,
  diners: diners,
  discover: discover,
  jcb: jcb,
  hipercard: hipercard,
  elo: elo,
  alipay: alipay,
  generic: generic,
  maestro: maestro
};

export const states = {
  "UF": [
    { "nome": "Acre", "sigla": "AC" },
    { "nome": "Alagoas", "sigla": "AL" },
    { "nome": "Amapá", "sigla": "AP" },
    { "nome": "Amazonas", "sigla": "AM" },
    { "nome": "Bahia", "sigla": "BA" },
    { "nome": "Ceará", "sigla": "CE" },
    { "nome": "Distrito Federal", "sigla": "DF" },
    { "nome": "Espírito Santo", "sigla": "ES" },
    { "nome": "Goiás", "sigla": "GO" },
    { "nome": "Maranhão", "sigla": "MA" },
    { "nome": "Mato Grosso", "sigla": "MT" },
    { "nome": "Mato Grosso do Sul", "sigla": "MS" },
    { "nome": "Minas Gerais", "sigla": "MG" },
    { "nome": "Pará", "sigla": "PA" },
    { "nome": "Paraíba", "sigla": "PB" },
    { "nome": "Paraná", "sigla": "PR" },
    { "nome": "Pernambuco", "sigla": "PE" },
    { "nome": "Piauí", "sigla": "PI" },
    { "nome": "Rio de Janeiro", "sigla": "RJ" },
    { "nome": "Rio Grande do Norte", "sigla": "RN" },
    { "nome": "Rio Grande do Sul", "sigla": "RS" },
    { "nome": "Rondônia", "sigla": "RO" },
    { "nome": "Roraima", "sigla": "RR" },
    { "nome": "Santa Catarina", "sigla": "SC" },
    { "nome": "São Paulo", "sigla": "SP" },
    { "nome": "Sergipe", "sigla": "SE" },
    { "nome": "Tocantins", "sigla": "TO" }
  ]
};

export const DEFAULT_TITLE = 'Pagamento';

export const DEFAULT_PAIS = 'BR';

export const colors = {
  filled: {
    backgroundColor: '#FFF',
    color: '#71DC56',
    border: 'solid 1px #71DC56'
  },
  progress: {
    backgroundColor: '#71DC56',
    color: '#FFF'
  }
};

export const typePayments = {
  credit_card: {
    id: 1,
    icon: credit,
    name: "Cartão de crédito",

  },
  debit_card: {
    id: 2,
    icon: "",
    name: "Cartão de débito",
  },
  pix: {
    id: 3,
    icon: pixMethod,
    name: "Pix",
  }
};

export const SALE_STATUS = {
  blocked: "blocked",
  denied: "denied",
  error: "error",
  expired: "expired",
  open: "open",
  paid: "paid",
  wait: "wait"
};

export function returnMessages(status, lang) {
  let translateMessage;

  switch (status) {
    case SALE_STATUS.denied:
      translateMessage = lang.msgTransaction;
      break;
    case SALE_STATUS.error:
      translateMessage = lang.msgErrorTransaction;
      break;
    case SALE_STATUS.paid:
      translateMessage = lang.msgConfirmedTransaction;
      break;
    default:
      break;
  }
  return translateMessage;
}

export const language = Storage.getCookie('Spag-language') || 'pt-br';
